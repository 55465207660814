const LandingImage = () => {
  return (
    <img
      src={require("../../assets/LandingLogo.png")}
      style={{
        width:687,
        height: 743,
        left: 753,
        top: 57,
        opacity: 0.87
      }}
    >
    </img>
  )
}

export { LandingImage }
